<template>
  <div>
    <title>SIM INVENTORY ~ ADD ORDER LIST DATA PROCESS DETAILS</title>
    <section class="content-header">
      <h1>
        Add Order List Data Process Details
        <br />
        <h4>Please enter transaction data, order list, process details</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction List Order Process Detail</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Order List Data Process Details</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Proses Code</label>
                    <input
                      type="hidden"
                      v-model="isidata.iddaftarordd"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Kode Proses"
                    />
                    <input
                      type="text"
                      v-model="isidata.kodeprosesd"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Please enter the process code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getdaftarproses()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namaprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date </label>
                  <input
                    type="date"
                    v-model="isidata.tglmulaiorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date </label>
                  <input
                    type="date"
                    v-model="isidata.tglselesaiorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="isidata.notesorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/daftar-order">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        iddaftarordd: "",
        kodeprosesd: "",
        namaprosesd: "",
        notesorderprosesd: "",
        tglmulaiorderprosesd: "",
        tglselesaiorderprosesd: ""
      }
    };
  },
  created() {
    // console.log(this.$route.params.id);
    this.isidata.iddaftarordd = this.$route.params.id;
  },
  methods: {
    async getdaftarproses() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_proses/getdaftar_prosesbykode?kode_proses=" + this.isidata.kodeprosesd;
      const urlAPIget =
        this.$apiurl +
        "daftar_proses/getdaftar_prosesbykode?kode_proses=" +
        this.isidata.kodeprosesd;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.kodeprosesd = resp.data.data.kode_proses;
          this.isidata.namaprosesd = resp.data.data.nama_proses;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async saveData() {
      this.loading = true;
      var kodeprosesd = this.isidata.kodeprosesd;
      var notesorderprosesd = this.isidata.notesorderprosesd;
      var tglmulaiorderprosesd = this.isidata.tglmulaiorderprosesd;
      var tglselesaiorderprosesd = this.isidata.tglselesaiorderprosesd;
      var iddaftarorderd = this.isidata.iddaftarordd;
      if (kodeprosesd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notesorderprosesd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglmulaiorderprosesd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Start Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglselesaiorderprosesd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "End Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          id_daftar_order_d: iddaftarorderd,
          kode_proses: kodeprosesd,
          notes: notesorderprosesd,
          kode_user: kodeuser,
          tanggal_mulai: tglmulaiorderprosesd,
          tanggal_selesai: tglselesaiorderprosesd
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPISaveDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_proses/savedaftar_order_d_proses";
        const urlAPISaveDatadetail =
          this.$apiurl + "daftar_order_d_proses/savedaftar_order_d_proses";
        axios
          .post(urlAPISaveDatadetail, paramdatadetail, { headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
